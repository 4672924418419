import {
  GraphqlLedger,
  Ledger,
  GraphqlCreditGrant,
  IssuedCreditGrant,
} from "./types";
import Decimal from "decimal.js";
import { FiatCreditType } from "types/credit-types";

export const parseLedger = (
  ledger: GraphqlLedger,
  availableBalanceCutoffDate: Date,
  grantId?: string,
): Ledger => {
  let pendingCharges = new Decimal(0);
  let pendingGrants = new Decimal(0);
  let consumed = new Decimal(0);
  let expired = new Decimal(0);
  let totalGranted = new Decimal(0);
  let runningBalance = new Decimal(0);
  let rolledOver = new Decimal(0);

  const formattedEntries = ledger.ledger_entries
    .filter((le) => (grantId ? le.credit_grant.id === grantId : true))
    .filter(
      (le) =>
        !le.credit_grant.voided_at ||
        le.__typename === "MRI_CreditGrantCreditLedgerEntry",
    )
    .map((le) => {
      const amount = new Decimal(le.amount);
      if (!le.credit_grant.voided_at) {
        switch (le.__typename) {
          case "MRI_CreditGrantCreditLedgerEntry":
          case "MRI_PendingRolloverCreditGrantLedgerEntry":
          case "MRI_RolloverCreditGrantLedgerEntry":
            totalGranted = totalGranted.add(amount.abs());
            if (new Date(le.effective_at) > availableBalanceCutoffDate) {
              pendingGrants = pendingGrants.add(amount.abs());
            }
            break;
          case "MRI_PendingRolloverDeductionLedgerEntry":
          case "MRI_RolloverDeductionLedgerEntry":
            rolledOver = rolledOver.plus(amount.abs());
            break;
          case "MRI_PendingChargeCreditLedgerEntry":
            // We don't use the absolute value here because pending charges
            // could be positive (refunds) or negative (deductions).
            // We subtract so that the common case of deductions causes
            // pendingCharges to increase.
            pendingCharges = pendingCharges.sub(amount);
            break;
          case "MRI_CreditExpirationCreditLedgerEntry":
            expired = expired.add(amount.abs());
            break;
          case "MRI_CreditDeductionCreditLedgerEntry":
            // As with pending charges, we don't use the absolute value here.
            consumed = consumed.sub(amount);
            break;
          case "MRI_PendingCreditExpirationCreditLedgerEntry":
            // Nothing to do here. Just adding this type for completeness
            break;
          default:
            le satisfies never;
        }
        runningBalance = runningBalance.add(le.amount);
      }
      return {
        __typename: le.__typename,
        creditType: ledger.credit_type,
        grant: {
          ...le.credit_grant,
          costBasis: le.credit_grant.cost_basis,
          voidedAt: le.credit_grant.voided_at,
        },
        amount: le.amount,
        runningBalance: runningBalance.toString(),
        effectiveAt: !le.credit_grant.voided_at
          ? new Date(le.effective_at)
          : new Date(le.credit_grant.voided_at),
        memo: le.memo,
      };
    });

  formattedEntries.sort((a, b) => {
    return a.effectiveAt < b.effectiveAt ? 1 : -1;
  });

  return {
    creditType: ledger.credit_type,
    availableBalance: totalGranted
      .sub(pendingGrants)
      .sub(consumed)
      .sub(expired)
      .sub(rolledOver)
      .sub(pendingCharges),
    consumed: consumed.add(pendingCharges),
    expired: expired,
    // Rollover amount needs to be subtracted from total grant to stop the rolled over portion of a
    // grant from being double counted when a rollover grant gets created.
    totalGranted: totalGranted.sub(rolledOver),
    ledgerEntries: formattedEntries,
  };
};

export const parseCreditGrant = (
  cg: GraphqlCreditGrant,
  ledger: GraphqlLedger,
  availableBalanceCutoffDate: Date,
): IssuedCreditGrant => {
  return {
    ...cg,
    amountGranted: cg.amount_granted,
    amountPaid: cg.amount_paid,
    costBasis: cg.cost_basis,
    createdAt: new Date(cg.created_at),
    effectiveAt: new Date(cg.effective_at),
    expiresBefore: new Date(cg.expires_before),
    amountGrantedCreditType: cg.AmountGrantedCreditType as FiatCreditType,
    amountPaidCreditType: cg.AmountPaidCreditType as FiatCreditType,
    ledger: parseLedger(ledger, availableBalanceCutoffDate, cg.id),
    voidedAt: cg.voided_at ? new Date(cg.voided_at) : null,
    invoice: cg.invoice
      ? {
          issued_at: new Date(cg.invoice.issued_at),
        }
      : null,
  };
};

// don't display credit grants for a currency type that only has voided grants
export const hasOnlyVoidedCreditGrants = (ledger: GraphqlLedger): boolean => {
  if (ledger.ledger_entries.length === 0) {
    return false;
  }
  return ledger.ledger_entries.every((entry) => entry.credit_grant.voided_at);
};
