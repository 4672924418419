import React from "react";
import classNames from "classnames";
import { Headline, Subtitle, Icon } from "design-system";

import { RoundedCurrency } from "lib/credits";
import { dayjs } from "lib/date";
import { RoundRectList } from "pages/Contracts/components/RoundRectList";
import { MriInvoiceFragment } from "components/Invoice/queries.graphql";
import Decimal from "decimal.js";
import { Invoice } from "pages/Contracts/lib/Invoice";
import { EmbeddableDashboardStatusPills } from "components/Invoice/components/StatusPills";

interface Props {
  loading: boolean;
  invoices: MriInvoiceFragment[];
  activeInvoiceId?: string;
  setActiveInvoiceId: (invoiceId?: string) => void;
}

export const InvoiceList: React.FC<Props> = (props) => {
  const now = dayjs();

  return (
    <RoundRectList
      loading={props.loading}
      items={props.invoices.map((invoice, i) => {
        const id = invoice.id ?? `index:${i}`;
        const active = id === props.activeInvoiceId;

        return {
          id,
          active,
          containerClassName: "bg-background",
          onClick: () => props.setActiveInvoiceId(id),
          content: (
            <>
              <div className="flex justify-between">
                <Headline
                  level={6}
                  className={classNames(
                    {
                      "text-deprecated-primary-600": active,
                    },
                    "text-[14px]",
                  )}
                >
                  {Invoice.renderDate(invoice, now)}
                </Headline>
                <div>
                  <EmbeddableDashboardStatusPills
                    invoice={invoice}
                    light={!active}
                    right={true}
                    className="flex-col gap-4"
                  />
                </div>
              </div>
              <Subtitle level={3} className="mt-8 text-gray-800">
                <Icon icon="cash" className="mr-4 align-middle text-sm" />
                <RoundedCurrency
                  amount={new Decimal(invoice.total)}
                  creditType={invoice.credit_type}
                />
              </Subtitle>
            </>
          ),
        };
      })}
    />
  );
};
