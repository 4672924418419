import React, { useEffect, useState } from "react";
import { Headline } from "design-system";
import { EmptyState } from "tenaissance/components/EmptyState";
import { useGetAllCreditGrantsAndLedgersQuery } from "./queries.graphql";
import { CreditGrantList } from "./components/CreditGrantList";
import { CreditType } from "types/credit-types";
import { displayCreditTypeName, USD_CREDIT_TYPE } from "lib/credits";
import { dayjs } from "lib/dayjs";
import Decimal from "decimal.js";
import styles from "./index.module.less";
import { ErrorEmptyState } from "lib/errors/ErrorEmptyState";
import classNames from "classnames";
import { GraphqlLedger, Ledger, IssuedCreditGrant } from "./types";
import CreditHeader from "./components/CreditHeader";
import {
  hasOnlyVoidedCreditGrants,
  parseCreditGrant,
  parseLedger,
  // parseCommits,
} from "./data";

const CustomerCreditsDashboard: React.FC = () => {
  const [scrollContentHeight, setScrollContentHeight] = useState(0);

  function handleResize() {
    setScrollContentHeight(window.innerHeight);
  }

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [selectedCreditType, setSelectedCreditType] = useState<CreditType>();

  const { data, loading, error } = useGetAllCreditGrantsAndLedgersQuery({
    variables: { include_v2_fields: false },
  });

  const creditTypesById: { [k: string]: CreditType } = {};
  const ledgersByCreditTypeId: { [k: string]: GraphqlLedger } = {};
  const formattedLedgersByCreditTypeId: { [k: string]: Ledger } = {};
  const creditGrantsById: { [k: string]: IssuedCreditGrant } = {};

  // When calculating the available balance for a ledger we do not want to include
  // credit grants that start in later billing periods. availableBalanceCutoffDate
  // will either be the end of the current billing period or the end of today
  // depending on whichever is later
  const availableBalanceCutoffDate = (
    data?.Customer[0].active_invoices.invoices ?? []
  ).reduce((latestEndDate, invoice) => {
    if (invoice.__typename !== "ArrearsInvoice") {
      return latestEndDate;
    }
    const invoiceEndDate = new Date(invoice.exclusive_end_date);
    return latestEndDate.valueOf() > invoiceEndDate.valueOf()
      ? latestEndDate
      : invoiceEndDate;
  }, dayjs.utc().endOf("day").toDate());

  if (
    !loading &&
    data &&
    data.Customer[0] &&
    data.Customer[0].ledgers.length > 0
  ) {
    data.Customer[0].ledgers.forEach((ledger) => {
      if (hasOnlyVoidedCreditGrants(ledger)) return; // filter out voided grants
      creditTypesById[ledger.credit_type.id] = ledger.credit_type;
      ledgersByCreditTypeId[ledger.credit_type.id] = ledger;
      formattedLedgersByCreditTypeId[ledger.credit_type.id] = parseLedger(
        ledger,
        availableBalanceCutoffDate,
      );
    });
    data.Customer[0].CreditGrants.forEach((cg) => {
      if (cg.voided_at) return; // filter out voided grants
      creditGrantsById[cg.id] = parseCreditGrant(
        cg,
        ledgersByCreditTypeId[cg.AmountGrantedCreditType.id],
        availableBalanceCutoffDate,
      );
    });
  }

  // const parsedCommits = parseCommits(data);
  const sortedCreditTypes = Object.values(creditTypesById).sort((a, b) =>
    displayCreditTypeName(a).localeCompare(displayCreditTypeName(b)),
  );

  React.useEffect(() => {
    if (!selectedCreditType && sortedCreditTypes.length > 0) {
      setSelectedCreditType(sortedCreditTypes[0]);
    }
  }, [selectedCreditType, sortedCreditTypes]);

  // If we have a selectedCreditType but no ledger then the client just submitted their
  // first grant in this credit type and we are waiting for the query to be updated. So
  // we return an empty ledger in this case so we don't flash the EmptyState
  const currentLedger = selectedCreditType
    ? formattedLedgersByCreditTypeId[selectedCreditType.id] || {
        creditType: selectedCreditType,
        availableBalance: new Decimal(0),
        consumed: new Decimal(0),
        expired: new Decimal(0),
        totalGranted: new Decimal(0),
        ledgerEntries: [],
      }
    : null;

  if (loading) {
    return <div></div>;
  }

  if (error) {
    return (
      <>
        <ErrorEmptyState
          title="There was a problem loading the page. Try refreshing."
          error={error}
        />
      </>
    );
  }

  const customerHasCreditGrants = !(
    !selectedCreditType ||
    !currentLedger ||
    Object.keys(creditGrantsById).length === 0
  );

  if (!customerHasCreditGrants) {
    return (
      <div className="mt-32">
        <EmptyState
          icon="bankNote03"
          mainText="No issued credit"
          supportingText="Once you issue a credit grant, you'll see their details here."
        />
      </div>
    );
  }

  if (data?.Customer.length === 0) {
    return (
      <div
        className="flex h-full w-full"
        style={{
          height: scrollContentHeight + "px",
        }}
      >
        <EmptyState
          icon="receipt"
          mainText="No credit grants found"
          supportingText=""
        />
      </div>
    );
  }

  return (
    <div
      style={{
        height: scrollContentHeight + "px",
      }}
    >
      {customerHasCreditGrants && (
        <div>
          <CreditHeader
            sortedCreditTypes={sortedCreditTypes}
            selectedCreditType={selectedCreditType}
            setSelectedCreditType={setSelectedCreditType}
            currentLedger={currentLedger}
          />
          <hr className={classNames("mb-[15px]", styles.divider)} />
        </div>
      )}
      <Headline
        level={6}
        className={classNames("mb-[15px]", styles.creditGrantsHeader)}
      >
        Your credit grants
      </Headline>
      <CreditGrantList
        issuedCreditGrants={Object.values(creditGrantsById)}
        selectedCreditType={selectedCreditType ?? USD_CREDIT_TYPE}
        parsedCommits={null}
      />
    </div>
  );
};

export default CustomerCreditsDashboard;
