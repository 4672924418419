import React from "react";

import { useRecentInvoicesQuery } from "./queries.graphql";
import { MriInvoiceFragment } from "components/Invoice/queries.graphql";

type InvoicesReq =
  | {
      state: "loading";
    }
  | {
      state: "success";
      cursor: string | null;
      customer: { id: string; name: string };
      invoices: MriInvoiceFragment[];
    }
  | {
      state: "error";
      error: Error;
    }
  | {
      state: "not found";
    };

export function useRecentInvoices(
  options: Parameters<typeof useRecentInvoicesQuery>[0],
): InvoicesReq {
  const req = useRecentInvoicesQuery(options);

  return React.useMemo((): InvoicesReq => {
    if (req.loading) {
      return { state: "loading" };
    }

    if (req.error) {
      return { state: "error", error: req.error };
    }

    if (!req.data || !req.data.Customer) {
      return { state: "not found" };
    }

    const customer = req.data.Customer[0];

    return {
      state: "success",
      cursor: customer.recentInvoices.cursor,
      customer: { id: customer.id, name: customer.name },
      invoices: customer.recentInvoices.invoices,
    };
  }, [req]);
}
