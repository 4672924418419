/* 841efbe97728413aac088b40ce2d5b1138235c65
 * This file is automatically generated by graphql-let. */

import * as Types from '../../../../../__types__';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RetrieveCustomerUsageQueryVariables = Types.Exact<{
  start_date: Types.Scalars['timestamptz'];
  end_date: Types.Scalars['timestamptz'];
}>;


export type RetrieveCustomerUsageQuery = { __typename?: 'Query', Customer: Array<{ __typename?: 'Customer', id: string, current_usage: Array<{ __typename?: 'CustomerCurrentUsage', group_key: string | null, billable_metric: { __typename?: 'BillableMetric', id: string, name: string }, usage: Array<{ __typename?: 'EmbeddableDashboardUsageWindow', end: string, start: string, metric_value: string, groups: Array<{ __typename?: 'EmbeddableDashboardUsageGroup', group_value: string, metric_value: string | null }> }> }> }> };


export const RetrieveCustomerUsageDocument = gql`
    query RetrieveCustomerUsage($start_date: timestamptz!, $end_date: timestamptz!) {
  Customer {
    id
    current_usage(start_date: $start_date, end_date: $end_date) {
      billable_metric {
        id
        name
        __environment_type: environment_type
      }
      group_key
      usage {
        end
        start
        metric_value
        groups {
          group_value
          metric_value
        }
      }
    }
    __environment_type: environment_type
  }
}
    `;

/**
 * __useRetrieveCustomerUsageQuery__
 *
 * To run a query within a React component, call `useRetrieveCustomerUsageQuery` and pass it any options that fit your needs.
 * When your component renders, `useRetrieveCustomerUsageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRetrieveCustomerUsageQuery({
 *   variables: {
 *      start_date: // value for 'start_date'
 *      end_date: // value for 'end_date'
 *   },
 * });
 */
export function useRetrieveCustomerUsageQuery(baseOptions: Apollo.QueryHookOptions<RetrieveCustomerUsageQuery, RetrieveCustomerUsageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RetrieveCustomerUsageQuery, RetrieveCustomerUsageQueryVariables>(RetrieveCustomerUsageDocument, options);
      }
export function useRetrieveCustomerUsageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RetrieveCustomerUsageQuery, RetrieveCustomerUsageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RetrieveCustomerUsageQuery, RetrieveCustomerUsageQueryVariables>(RetrieveCustomerUsageDocument, options);
        }
export type RetrieveCustomerUsageQueryHookResult = ReturnType<typeof useRetrieveCustomerUsageQuery>;
export type RetrieveCustomerUsageLazyQueryHookResult = ReturnType<typeof useRetrieveCustomerUsageLazyQuery>;
export type RetrieveCustomerUsageQueryResult = Apollo.QueryResult<RetrieveCustomerUsageQuery, RetrieveCustomerUsageQueryVariables>;